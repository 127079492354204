<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 门店列表 -->
          <el-tabs v-model="storeId" @tab-click="changeTab" class="mt-20">
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in storeList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button
                class="ml-15"
                type="primary"
                @click="showBannerDialog('add')"
                >新增图片</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="banner名称" prop="bannerName"></el-table-column>
              <el-table-column label="类型" prop="type">
              <template slot-scope="scope">
                {{ getString(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column label="banner图片" prop="picture">
              <template slot-scope="scope">
                <el-avatar
                  :src="
                    scope.row.picture.indexOf(',') > -1
                      ? scope.row.picture.split(',')[0]
                      : scope.row.picture
                  "
                  shape="square"
                  alt="加载失败"
                  :key="Math.random()"
                ></el-avatar>
              </template>
            </el-table-column>
             <el-table-column label="banner跳转" prop="turnUrl"></el-table-column>
              <el-table-column label="分享图片" prop="sharePicture">
              <template slot-scope="scope" v-if="scope.row.sharePicture">
                <el-avatar
                  :src="
                    scope.row.sharePicture
                  "
                  shape="square"
                  alt="加载失败"
                  :key="Math.random()"
                ></el-avatar>
              </template>
            </el-table-column>
              <el-table-column label="分享图标题" prop="shareTitle"></el-table-column>
          
           
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isUp"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    @click="showBannerDialog('edit', scope.row.id)"
                    class="tip-text-edit"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteBannerPic(scope.row.id)"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="showBanner"
      :title="opeType == 'add' ? '添加图片' : '编辑图片'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="banner_form"
          :rules="rules"
          ref="banner_form"
        >
          <el-form-item label="类型" prop="type">
            <el-select
              placeholder="请选择类型"
              v-model="banner_form.type"
              class="w-100"
            >
              <el-option
                v-for="(item, index) in bannerType"
                :key="index"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="bannerName">
            <el-input
              placeholder="请输入图片名称"
              v-model="banner_form.bannerName"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="添加banner图片">
            <el-upload
              :action="picsPath()"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :limit="banner_form.type == 1 || banner_form.type == 2 ? 1 : 5"
              :file-list="fileListArr"
              :on-exceed="handleExceed"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span v-if="banner_form.type"
              >建议图片尺寸
              {{
                banner_form.type == 1
                  ? "700*420"
                  : banner_form.type == 2
                  ? "700*280"
                  : "700*450"
              }}
              ，图片大小不超过 500 kb</span
            >
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item
            label="跳转路径"
            prop="isTurn"
            v-if="banner_form.type == 1 || banner_form.type == 2"
          >
            <el-radio v-model="banner_form.isTurn" :label="1">跳转</el-radio>
            <el-radio v-model="banner_form.isTurn" :label="0">不跳转</el-radio>
          </el-form-item>
          <el-form-item
            label="跳转路径"
            prop="turnUrl"
            v-if="
              banner_form.isTurn == 1 &&
                (banner_form.type == 1 || banner_form.type == 2)
            "
          >
            <el-input
              placeholder="请输入跳转路径"
              v-model="banner_form.turnUrl"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="标题" prop="shareTitle" v-if="banner_form.type > 2">
            <el-input
              placeholder="请输入分享标题"
              v-model="banner_form.shareTitle"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="添加分享图片" v-if="banner_form.type > 2">
            <el-upload
              :action="picsPath()"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemoveShare"
              :on-success="handleSuccessShare"
              :limit="1"
              :file-list="fileListArrShare"
              :on-exceed="handleExceed"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span v-if="banner_form.type"
              >建议图片尺寸 500*400 ，图片大小不超过 500 kb</span
            >
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showBanner = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import {
  getStoreList,
  getMerchantsList,
} from "@/api/online_department_store/commodity_manage";
import {
  getBannerList,
  bannerUpDownShelf,
  getBannerDetail,
  bannerDelete,
  subBannerForm,
} from "@/api/config_center/miniprogram_banner";
export default {
  name: "miniprogram_banner",
  components: {
    Pagination,
    UploadPics,
  },
  data() {
    return {
      // 顶部搜索栏数据
      keyword: "",
      // 会员表格数据内容
      tableData: [],
      showBanner: false,
      opeType: "",
      banner_form: {
        bannerName: "",
        isTurn: null,
        storeId: "",
        type: "",
        turnUrl: "",
      },
      rules: {
        bannerName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        isTurn: [{ required: true }],
        storeId: [{ required: true, message: "门店不能为空" }],
        type: [{ required: true, message: "请选择类型" }],
        // merchantId: [{ required: true, message: "商户不能为空"}],
        turnUrl: [
          { required: true, message: "跳转路径不能为空", trigger: "blur" },
        ],
      },
      bannerType: [
        //1百货 2商超
        { type: 1, name: "百货banner" },
        { type: 2, name: "商超banner" },
        { type: 3, name: "分享砍价" },
        { type: 4, name: "分享集卡" },
        { type: 5, name: "多人拼团" },
        { type: 6, name: "限时抢购" },
      ],
      storeList: [],
      merchantList: [],
      storeId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      // 美加的
      dialogImageUrl: "",
      dialogVisible: false,
      fileListArr: [],  //banner图
      fileListArrShare:[], //分享图
    };
  },
  mounted() {
    this.getStores();
    // this.getMerchantsListHandle()
  },
  methods: {
    // 搜索事件
    search(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }

      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // keyword: this.keyword,
        storeId: this.storeId,
      };
      getBannerList(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
          this.tableData.map((item) => {
            item.picture = this.picsPath(item.picture);
            item.sharePicture =item.sharePicture ? this.picsPath(item.sharePicture):''
          });
        }
      });
    },
    // 获取订单状态与类型
    getString(val) {
      let temp = "";
      this.bannerType.forEach((e) => {
        if (e.type == val) {
          temp = e.name;
        }
      });
      return temp;
    },
    // 重置搜索关键词
    reset() {
      this.keyword = "";
      this.search(1);
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 切换tab
    changeTab(tab, event) {
      this.storeId = tab.name;
      this.search(1);
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.storeList = res.data.body.list;
        this.storeId = res.data.body.list[0].id + "";
        this.search(1);
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      getMerchantsList().then((res) => {
        if (res.data.body && res.data.body.list) {
          this.merchantList = res.data.body.list;
        }
      });
    },
    //banner图移除
    handleRemove(file, fileList) {
      this.fileListArr = fileList;
    },
    //分享图移除
    handleRemoveShare(file, fileList){
      this.fileListArrShare = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //banner图 上传成功
    handleSuccess(response, file, fileList) {
      console.log(fileList)
      this.fileListArr = fileList;
    }, 
     //分享图  上传成功
    handleSuccessShare(response, file, fileList) {
      this.fileListArrShare = fileList;
    },
    //文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message({
        type: "info",
        message:
          "百货banner,商超banner和分享图最多允许传一张, 其他最多可允许5张",
      });
    },
    //文件大小超出
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传活动图片大小不能超过 500KB!");
      }
      return isLt2M;
    },
    //上下架
    upDownShelf(e, id, scope) {
      bannerUpDownShelf(id, e)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.getMerchantsListHandle();
          this.search();
        })
        .catch((e1) => {
          this.$message({ type: "error", message: e1 });
          this.getMerchantsListHandle();
          this.search();
          this.$set(
            this.tableData[scope.$index],
            "isUp",
            Math.abs(this.tableData[scope.$index].isUp - 1)
          );
        });
    },
    //获取单个Banner 详情  编辑
    getBanner(id) {
      getBannerDetail(id).then((res) => {
        console.log(res.data.body)
        this.banner_form = res.data.body;
        // console.log(res.data.body);
        let newArr = res.data.body.picture.split(",").map((ele) => {
          return {
            url: process.env.VUE_APP_BASE_API_IMG + "/file-service/file/" + ele,
          };
        });
        // console.log(newArr);
        this.fileListArr = newArr;
        this.fileListArrShare =  res.data.body.sharePicture? [{'url':process.env.VUE_APP_BASE_API_IMG + "/file-service/file/" + res.data.body.sharePicture}] :[]
        // console.log(this.fileListArr);
      });
    },
    //编辑 或者 添加 有id是编辑
    showBannerDialog(type, id) {
      this.showBanner = true;
      // console.log("storeId", this.storeId);
      this.opeType = type;
      try {
        this.$refs["banner_form"].resetFields();
      } catch (err) {}
      if (id) {
        this.getBanner(id);
      } else {
        //添加
        this.banner_form.storeId = this.storeId;
        this.banner_form.id = "";
        this.banner_form.type = "";
        this.fileListArr = [];
        this.fileListArrShare = [];
      }
    },

    clearDialog() {
      this.banner_form = {
        bannerName: "",
        picture: "",
        isTurn: null,
        storeId: "",
        turnUrl: "",
        type: "",
        // merchantId: ""
      };
    },
    deleteBannerPic(id) {
      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          bannerDelete(id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    submit() {
      this.$refs["banner_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        if (this.fileListArr.length == 0) {
          this.$message({
            message: "请添加banner图片",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      // console.log(this.fileListArr);
      let arr = []; 
      this.fileListArr.forEach((ele) => {
        if (ele.response) {
          arr.push(ele.response.body);
        } else if (ele.url) {
          let str = "";
          let newArr = ele.url.split("/file/");
          if (arr.indexOf(newArr[1]) == -1) {
            arr.push(newArr[1]);
          }
        }
      });
      this.fileListArrShare.forEach(ele=>{
          if (ele.response) {
          this.banner_form.sharePicture = ele.response.body;
        } else if (ele.url) {
          let str = "";
          let newArr = ele.url.split("/file/");
          this.banner_form.sharePicture  = newArr[1];
        }
      })
      this.banner_form.picture = arr.join(",");
      console.log(this.fileListArrShare)
      let data = this.banner_form;
      console.log(data);
      subBannerForm(data, this.opeType == "edit" ? 1 : 0).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.showBanner = false;
          this.clearDialog();
          this.search();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
