import request from '@/utils/request'

// 获取banner列表
export function getBannerList (data) {
    return request({
        url: '/shd-operation/lrBanner/queryLrBannerList',
        method: 'post',
        data
    })
}

// banner上下架
export function bannerUpDownShelf (id, status) {
    return request({
        url: `/shd-operation/lrBanner/upOrDown?id=${id}&isUp=${status}`,
        method: 'post'
    })
}

// 根据id获取banner详情
export function getBannerDetail (id) {
    return request({
        url:  `/shd-operation/lrBanner/queryLrBannerDetail?id=${id}`,
        method: 'get',
    })
}

// 根据id删除banner
export function bannerDelete (id) {
    return request({
        url: `/shd-operation/lrBanner/delLrBanner?id=${id}`,
        method: 'delete'
    })
}


// 提交新增、编辑banner数据
//接口：新增banner
//地址：http://${BASE_URL}/shd-operation/lrBanner/addLrBanner
export function subBannerForm (data, type) { // type 1 编辑 0 新增
    return request({
        url: type == 1 ? '/shd-operation/lrBanner/updateLrBanner': '/shd-operation/lrBanner/addLrBanner',
        method: 'post',
        data
    })
}